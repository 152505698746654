import React, { useEffect, useRef, useState } from 'react'
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { eshop } from "../../../../redux/reducers";
import BuyerDfltImg from '../../../../dist/images/buyer/1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';

const MyOrder = ({ accessToken, myOrderInfo }) => {
    const [show, setShow] = useState(false);
    const [token,] = useState(accessToken);
    const [details, setDetails] = useState([]);

    const [timerDays, setTimerDays] = useState('00');
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');

    let interval = useRef();

    const startTimer = (timeCountdownWeb) => {
        const countdownDate = new Date(timeCountdownWeb).getTime();

        interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 *24));
            const hours = Math.floor((distance % (1000 * 60 * 60 *24) / (1000 * 60 * 60)));
            const minutes = Math.floor((distance % (1000 * 60 * 60 )) / (1000 * 60 ));
            const seconds = Math.floor((distance % (1000 * 60 )) / 1000);

           if(distance < 0) {
               //stop our timer
               clearInterval(interval.current);
           } else {
               //update timer
               setTimerDays(days);
               setTimerHours(hours);
               setTimerMinutes(minutes);
               setTimerSeconds(seconds);
           }

        }, 1000);
    };

    useEffect(() => {
        return () => {
            clearInterval(interval.current);
        };
    });

    const prodictShow = (code) => {
        Axios.get(ApiUrl.purchaseHistoryDetails(code), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                setDetails(response.data)
                setShow(true)
                startTimer(response.data.timeCountdownWeb);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                this.props.setAccessToken()
                this.props.setUserInfo({})
            } else {
                toast.error("Request failed ! try again");
            }
        })

    }
    return (
        <div>
            <div className="buyer-profile-wrap">
                <div className="buyer-profile-main">
                    <div className="row">
                        <div className="order-details">
                            <div className="row">
                                {
                                    myOrderInfo.map((dt, i) => {
                                        return (
                                            <div key={i} className="col-md-9">
                                                <div className="row order-list" style={{ width: '100%' }}>
                                                    <div className="col-md-3">
                                                        <p className="d-none d-lg-block">{i + 1}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p className="fw-bold" style={{ cursor: 'pointer' }} onClick={() => {
                                                            prodictShow(dt.code)
                                                        }}>{dt.code}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p className="order-date">{dt.date}</p>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <p>{dt.status}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-md-3">
                        </div>
                    </div>
                </div>
            </div>
            {
                show &&
                <div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                            <h6 className="title-6">TRACK ORDER</h6>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="">
                                        <strong className="">ORDER ID</strong> <br />
                                        <span style={{ fontSize: "12px" }}>{details.code}</span>
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <p className="text-center">
                                        <strong className="">TRACKING ID</strong> <br />
                                        <span style={{ fontSize: "12px" }}>{details.code}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="time-counter-title">
                                <p>Expected Delivery Time :</p>
                            </div>
                            <div className="time-counter-wrap">
                                <section>
                                    <p>{timerDays}</p>
                                    <p><small className="time-title">Days</small></p>
                                </section>
                                <span>:</span>
                                <section>
                                    <p>{timerHours}</p>
                                    <p><small className="time-title">Hours</small></p>
                                </section>
                                <span>:</span>
                                <section>
                                    <p>{timerMinutes}</p>
                                    <p><small className="time-title">Minutes</small></p>
                                </section>
                                <span>:</span>
                                <section>
                                    <p>{timerSeconds}</p>
                                    <p><small className="time-title">Seconds</small></p>
                                </section>
                            </div>
                            <div className="row">
                                {details?.delivery_status === "canceled" ? (
                                    <div className="stepper d-flex flex-column mt-5 ml-2 pt-2">
                                        <div className="d-flex mb-1">
                                            <div className="rounded-box d-flex flex-column pr-4 align-items-center mr-2">
                                                {details?.delivery_status === "canceled" ?
                                                    <div className="active-rounded"></div> : ""}
                                                <div
                                                    className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                                <div className="line h-100 d-none"></div>
                                            </div>
                                            <div>
                                                <h6 className="text-dark">CANCELED</h6>
                                                <p className="lead text-muted pb-3"></p>
                                            </div>
                                        </div>
                                    </div>

                                ) : (
                                    <div className="stepper d-flex flex-column mt-5 ml-2 pt-2">
                                        <div className="d-flex mb-1">
                                            <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                                {/*{details?.delivery_status}*/}
                                                {details?.delivery_status === "order_place" ?
                                                    <div className="active-rounded"></div> : ""}
                                                <div
                                                    className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                                <div className="line active-line h-100"></div>
                                            </div>
                                            <div>
                                                <h6 className="text-dark">ORDER PLACE</h6>
                                                <p className="lead text-muted pb-3">{details.created_at}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1">
                                            <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                                {details?.delivery_status === "confirmed" ?
                                                    <div className="active-rounded"></div> : ""}
                                                <div
                                                    className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                                <div className="line active-line h-100"></div>
                                            </div>
                                            <div>
                                                <h6 className="text-dark">CONFIRMED</h6>
                                                <p className="lead text-muted pb-3"></p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1">
                                            <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                                {details?.delivery_status === "picked" ?
                                                    <div className="active-rounded"></div> : ""}
                                                <div
                                                    className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                                <div className="line active-line h-100"></div>
                                            </div>
                                            <div>
                                                <h6 className="text-dark">PICKED</h6>
                                                <p className="lead text-muted pb-3"></p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1">
                                            <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                                {details?.delivery_status === "shipped" ?
                                                    <div className="active-rounded"></div> : ""}
                                                <div
                                                    className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                                <div className="line active-line h-100"></div>
                                            </div>
                                            <div>
                                                <h6 className="text-dark">SHIPPED</h6>
                                                <p className="lead text-muted pb-3"></p>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-1">
                                            <div className="rounded-box d-flex flex-column pr-4 align-items-center mr-2">
                                                {details?.delivery_status === "delivered" ?
                                                    <div className="active-rounded"></div> : ""}
                                                <div
                                                    className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                                <div className="line active-line h-100 d-none"></div>
                                            </div>
                                            <div>
                                                <h6 className="text-dark">DELIVERED</h6>
                                                <p className="lead text-muted pb-3"></p>
                                            </div>
                                        </div>

                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className="title-6">ORDERED FROM</h6>
                                    <div className="od-product-box">
                                        {
                                            details?.products.map((pro, i) => {
                                                return (
                                                    <div className="row" key={i}>
                                                        <div className="col-md-4">
                                                            <div className="light-gray-box text-center p-2">
                                                                <img src={ApiUrl.showImage(pro.thumbnail_img)} alt="Product Image" title="Product Image" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <h6 className="title-6">{pro.name}</h6>
                                                            {/* <div className="rating-box">
                                                                    <span className="p-1"><FontAwesomeIcon icon={faStar} /></span>
                                                                    <span className="p-1"><FontAwesomeIcon icon={faStar} /></span>
                                                                    <span className="p-1"><FontAwesomeIcon icon={faStar} /></span>
                                                                    <span className="p-1"><FontAwesomeIcon icon={faStar} /></span>
                                                                </div> */}
                                                            <p className="">{details.user.district}, {details.user.division}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="bill-to-heading">
                                        <h6 className="text-uppercase">BILL To</h6>
                                        <a href={`https://backend.eshoppingmall.xyz/invoice/customer/${details.id}`} target="_blank"><h6 className="bill-print-btn text-uppercase"> <FontAwesomeIcon
                                            icon={faPrint} /><span style={{ marginLeft: "3px" }}> Print</span></h6></a>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-4 col-3">
                                            {details.billingDifferentAddress ?
                                                <img className="img-fluid rounded-circle" src={BuyerDfltImg} alt="profile" title="profile" /> :
                                                details.user.avatar_original ? <img className="img-fluid rounded-circle"
                                                    src={ApiUrl.showImage(details.user.avatar_original)}
                                                    alt="profile" title="profile" /> :
                                                    <img className="img-fluid rounded-circle" src={BuyerDfltImg}
                                                        alt="profile" title="profile" />}

                                        </div>
                                        <div className="col-lg-9 col-md-9 col-sm-8 col-9">
                                            <div className="right-side-info">
                                                {details.billingDifferentAddress ? <div>
                                                    <h6 className="buyer-name text-uppercase">{details.billingDifferentAddress.billingName ? details.billingDifferentAddress.billingName : ""} {details.billingDifferentAddress.billingLastName ? details.billingDifferentAddress.billingLastName : ""}</h6>
                                                    <p className="">{details.billingDifferentAddress.billingPhone ? details.billingDifferentAddress.billingPhone : ""}</p>
                                                    <p style={{ margin: "0px" }} className="">{details.billingDifferentAddress.billingAddress ? details.billingDifferentAddress.billingAddress : ""} {"," + details.billingDifferentAddress.billingOtherAddress ? details.billingDifferentAddress.billingOtherAddress : ""} </p>
                                                    <p className="">{details.billingDifferentAddress.billingCity ? details.billingDifferentAddress.billingCity : ""} </p>
                                                </div> : <div>
                                                    <h6 className="buyer-name text-uppercase">{details.user ? details.user.name : ""}</h6>
                                                    <p className="">{details.user ? details.user.phone : ""}</p>
                                                    <p className="">{details.user ? details.user.address : ""} {details.user ? "" + details.user.district : ""} {details.user ? "," + details.user.division : ""}</p>
                                                    <p className="">Contact
                                                        No: {details.user ? details.user.phone : ""}</p>
                                                </div>}
                                                <h6 className="buyerlv-add-title">Delivery Address</h6>
                                                <p style={{ margin: "0px" }} className="">{details?.shipping_address.address ? details?.shipping_address.address : ""}</p>
                                                <p style={{ margin: "0px" }} className="">{details?.shipping_address.otherAddress ? details?.shipping_address.otherAddress : ""}</p>
                                                <p className="">{details?.shipping_address.division ? details?.shipping_address.division : ""} {details?.shipping_address.city}</p>
                                                {/*<Link className="refund-req-link btn btn-outline-dark px-5" to="">REFUND REQUEST</Link>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </div>
    )
}


const mapStateToProps = state => ({
    userInfo: state.builder.userInfo,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(MyOrder);