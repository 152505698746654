import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios";
import {toast, Toaster} from "react-hot-toast";
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";

class Login extends Component{
    constructor() {
        super();
        this.state={
            mobile:"",
            password:"",
            checkRedirect:false,
        }
    }

    mobileOnChange=(event)=>{
        let mobile = event.target.value
        this.setState({mobile:mobile})
    }
    passwordOnChange=(event)=>{
        let password = event.target.value
        this.setState({password:password})
    }
    onSubmit=()=>{
        let mobile = this.state.mobile;
        let password = this.state.password;
        if(mobile.length === 0 || mobile.length < 11 || password.length < 8 || password.length === 0){
            if(password.length < 8){
                toast.error("Please check password")
            }
            if(mobile.length < 11){
                toast.error("Please check mobile number")
            }
        }else{
            let myFormData = new FormData();
            myFormData.append("phone", mobile);
            myFormData.append("password", password);
            Axios.post(ApiUrl.Login,myFormData).then((response)=>{
                if(response.status===200 && response.data.status===1){
                    toast.success("Login Success")
                    this.setState({checkRedirect: true})
                    this.props.setAccessToken(response.data.access_token)
                    this.props.setUserInfo(response.data.user)
                    this.props.setGuestInfo({})
                    this.props.setEstimateInfo({})
                    this.onUserRedirect();
                }else{
                    toast.error("Please check mobile number and password")
                }
            }).catch(function(error){
                toast.error("Request Failde ! Try Again");
            })
        }
    }
    onUserRedirect = () =>{
        if(this.state.checkRedirect === true){
            if(this.props.previousUrl == "/cart-info"){
                    return <Redirect to="/cart-shipping"/>;
                }else if(this.props.previousUrl == "/phone-signup"){
                    return <Redirect to="/"/>;
                }else if(this.props.previousUrl == "/reg-otp-verification" || this.props.previousUrl == '/'){
                    return <Redirect to="/"/>;
                }else if(this.props.previousUrl == "/"){
                    return <Redirect to="/"/>;
                }
                else{
                    return <Redirect to={this.props.previousUrl}/>;
                }
        }
    }

    render(){
        return(
            <div>
                <Header/>
                <MobileHeader/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto">
                            <div className="d-block d-sm-none py-5 my-4 text-center" style={{lineHeight:'18px'}}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span style={{fontSize:'20px', fontWeight:300}}>Shoppingmall</span></p>
                                    <span>.XYZ</span>
                                </div>
                            </div>
                            <div className="login-body pb-5 pt-sm-5 my-sm-5 login-body-mobile-view">
                                <h1 className="title-h3 text-center text-uppercase">Login</h1>
                                <p className="text-center">Please enter your mobile number and password:</p>
                                <form className="form" onKeyDown={ (event) => { if (event.keyCode === 13) {
                                    this.onSubmit();
                                }}}>
                                    <div className="form-group mb-3">
                                        <input type="number" onChange={this.mobileOnChange} className="form-control" placeholder="Mobile Number" />
                                    </div>
                                    <div className="form-group mb-3 forgot-pass-rel-div">
                                        <input type="password" onChange={this.passwordOnChange} className="form-control" placeholder="Password" />
                                        <Link to="/recover-password" className="forgot-txt create-link-txt">Forgot Password?</Link>
                                    </div>
                                    <div className="form-group mb-3">
                                        <span onClick={this.onSubmit} className="btn base-button text-light d-block text-uppercase">Login</span>
                                    </div>
                                    <div className="form-group text-center">
                                        <span>Don’t have an account? </span>
                                        <Link to="/phone-signup" className="create-link-txt"> Create one</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster position="top-right" reverseOrder={false}/>
                {this.onUserRedirect()}
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cartTotalItem: state.builder.cartTotalItem,
    setCartTotalItem: state.builder.setCartTotalItem,
    guestId: state.builder.guestId,
    accessToken: state.builder.accessToken,
    previousUrl: state.builder.previousUrl,
    userInfo: state.builder.userInfo,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(Login);