import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import ShippingAmountTotal from './components/ShippingAmountTotal';
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import deliveryImg from "../../../dist/images/cod.png";
import onlinePayImg from "../../../dist/images/onlinePay.png";
import quesImg from "../../../dist/images/ques.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import TramsModal from "./TramsAndConditionModal/TramsModal";
import ReturnPolicy from './TramsAndConditionModal/ReturnPolicy';
import PrivacyPolicy from './TramsAndConditionModal/PrivacyPolicy';
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";

class CartPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            billingAddress1: 'd-block',
            billingAddress2: 'd-none',
            cartData: [],
            userIdentity: "",
            user_id: "",
            guest_id: "",
            type: "",
            email: "",
            firstName: "",
            lastName: "",
            address: "",
            otherAddress: "",
            division: "",
            district: "",
            thana: "",
            postCode: "",
            phone: "",
            agree: false,
            billing: 1,
            billingName: "",
            billingLastName: "",
            billingAddress: "",
            billingOtherAddress: "",
            billingCity: "",
            billingPhone: "",
            grandTotal: this.props.grandTotal,
            payment_type: "cash_on_delivery",
            payment_button_name: "Submit",
            UserRedirect: false,
            token: this.props.accessToken,
        };
        this.onChangeValue = this.onChangeValue.bind(this);
    }
    componentDidMount() {
        if (this.props.accessToken) {
            this.setState({ user_id: this.props.userInfo.id, type: "user"})
            let user = this.props.shippingAddress;
            let userPer = this.props.userInfo;
            this.setState({ address: user.address, otherAddress: user.otherAddress, email: userPer.email, division: user.divisionId, district: user.districtId, phone: user.phone, postCode: user.postal_code, firstName: user.firstName, lastName: user.lastName })
        } else {
            let user = this.props.guestInfo;
            this.setState({ guest_id: this.props.guestId,  type: "guest" })
            let divisions = JSON.parse(this.props.division);
            let districts = JSON.parse(this.props.district);
            this.setState({ address: user.address, otherAddress: user.otherAddress,  email: user.email, division: divisions.find(item => item.id == user.divisionId).name, phone: user.phone, postCode: user.postCode, firstName: user.firstName, lastName: user.lastName })
            this.setState({ district: districts.find(item => item.id == user.districtId).name })
        }
    }
    onChangeValue(event) {
        this.setState({ billing: event.target.value })
        if (event.target.value === "1") {
            this.setState({ billingAddress1: 'd-block' })
            this.setState({ billingAddress2: 'd-none' })
        } else if (event.target.value === "2") {
            this.setState({ billingAddress1: 'd-none' })
            this.setState({ billingAddress2: 'd-block' })
        };
    }
    agree = (event) => {
        this.setState({ agree: event.target.checked })
        document.getElementById("agree-policies").style.background = "";
    }
    agreeMst = () => {
        document.getElementById("agree-policies").style.background = "#f00";
    }
    getPaymentOption = (event) =>{
        this.setState({payment_type: event.target.value})
        if(event.target.value == "sslcommerz"){
            this.setState({payment_button_name: "Pay Now"})
        }else{
            this.setState({payment_button_name: "Submit"})
        } 
    }
    onUserRedirect(){
        if(this.state.UserRedirect===true){
            return <Redirect  to={"/payment-success"} />
        }
    }
    payNow = () => {
        if (this.state.billing == 2) {
            if (this.state.billingName === "" || this.state.billingLastName === "" || this.state.billingAddress === "" || this.state.billingCity === "" || this.state.billingPhone === "") {
                if (this.state.billingName.length > 0) {
                    document.getElementById("name").style.border = "";
                } else {
                    document.getElementById("name").style.border = "1px solid red";
                }
                if (this.state.billingLastName.length > 0) {
                    document.getElementById("lastName").style.border = "";
                } else {
                    document.getElementById("lastName").style.border = "1px solid red";
                }
                if (this.state.billingAddress.length > 0) {
                    document.getElementById("address").style.border = "";
                } else {
                    document.getElementById("address").style.border = "1px solid red";
                }
                if (this.state.billingCity.length > 0) {
                    document.getElementById("city").style.border = "";
                } else {
                    document.getElementById("city").style.border = "1px solid red";
                }
                if (this.state.billingPhone.length > 0) {
                    document.getElementById("phone").style.border = "";
                } else {
                    document.getElementById("phone").style.border = "1px solid red";
                }
            } else {
                let billingDifferentAddress = {
                    "billingName": this.state.billingName,
                    "billingLastName": this.state.billingLastName,
                    "billingAddress": this.state.billingAddress,
                    "billingOtherAddress": this.state.billingOtherAddress,
                    "billingCity": this.state.billingCity,
                    "billingPhone": this.state.billingPhone,
                }
                this.orderSubmit(billingDifferentAddress);
            }
        } else {
            this.orderSubmit();
        }
    }
    orderSubmit = (billingDifferentAddress = null) => {
        let email = this.state.email
        let firstName = this.state.firstName
        let lastName = this.state.lastName
        let address = this.state.address
        let otherAddress = this.state.otherAddress
        let division = this.state.division
        let district = this.state.district
        let thana = this.state.thana
        let postCode = this.state.postCode
        let phone = this.state.phone
        let payment_type = this.state.payment_type
        const payNowValue = {
            email: email,
            address: address,
            postCode: postCode,
            phone: phone,
        }
        if (firstName === '' || address === '' || division === '' || district === "" || postCode === '' || phone === '') {
            if (firstName.length === 0) {
                toast.error("First name Required")
            }
            if (address.length === 0) {
                toast.error("Address name Required")
            }
            if (division.length === 0) {
                toast.error("City name Required")
            }
            if (postCode.length === 0) {
                toast.error("Thana name Required")
            }
            if (phone.length === 0) {
                toast.error("Phone number Required")
            }
        } else {
            localStorage.setItem("payNowInfo", JSON.stringify(payNowValue))
            let data = {
                "email": email,
                "firstName": firstName,
                "lastName": lastName,
                "address": address,
                "otherAddress": otherAddress,
                "division": division,
                "district": district,
                "thana": thana,
                "postCode": postCode,
                "phone": phone,
            }
            let myFormData = new FormData();
            myFormData.append("user_id", this.state.user_id);
            myFormData.append("guest_id", this.state.guest_id);
            myFormData.append("type", this.state.type);
            myFormData.append("cart", JSON.stringify(this.props.cart));
            myFormData.append("shipping_address", JSON.stringify(data));
            myFormData.append("billingDifferentAddress", JSON.stringify(billingDifferentAddress));
            myFormData.append("payment_type", payment_type);
            myFormData.append("payment_status", "Unpaid");
            myFormData.append("grand_total", this.state.grandTotal);
            myFormData.append("coupon_discount", this.props.couponDiscount.discount > 0 ? this.props.couponDiscount.discount : 0);
            myFormData.append("coupon_code", this.props.couponDiscount.code > 0 ? this.props.couponDiscount.code: "");
            myFormData.append("date", this.props.estimateInfo.date);
            myFormData.append("time", this.props.estimateInfo.time);

            Axios.post(ApiUrl.orderStore, myFormData).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    LocalStorageHelper.setItem("orderId", response.data.orderId)
                    if(this.state.payment_type === "sslcommerz"){
                        let myFormData = new FormData();
                        myFormData.append("order_id", response.data.id);
                        Axios.post(ApiUrl.SslcommerzPay, myFormData).then((response) => {
                            if (response.status === 200 && response.data.status === 1) {
                                window.open(response.data.url, "_self");
                            } else if (response.status === 200 && response.data.status === 0) {
                                for (let error of response.data.message) {
                                    toast.error(error)
                                }
                            }
                        }).catch(function () {
                            toast.error("Request failed ! try again");
                        })
                        this.props.setEstimateInfo(" ");
                        this.props.setSubTotal();
                        this.props.setGrandTotal();
                        this.props.setCouponDiscount({});
                        this.props.setGuestInfo({});
                        this.props.setCart([]);
                    }else{
                        this.setState({UserRedirect: true})
                        this.props.setEstimateInfo(" ");
                        this.props.setSubTotal();
                        // this.props.setGrandTotal();
                        this.props.setCouponDiscount({});
                        this.props.setGuestInfo({});
                        this.props.setCart([]);
                    }
                } else if (response.status === 200 && response.data.status === 0) {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                    this.setState({ btn: "Continue" })
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        }
    }

    // Use a different billing address
    getName = (event) => {
        this.setState({ billingName: event.target.value })

    }
    getLastName = (event) => {
        this.setState({ billingLastName: event.target.value })
    }
    getAddress = (event) => {
        this.setState({ billingAddress: event.target.value })
    }
    getOtherAddress = (event) => {
        this.setState({ billingOtherAddress: event.target.value })
    }
    getCity = (event) => {
        this.setState({ billingCity: event.target.value })
    }
    getPhone = (event) => {
        this.setState({ billingPhone: event.target.value })
    }

    render() {
        
        return (
            <div className="">
                <Header />
                <MobileHeader />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 pe-md-5 mb-3 mb-md-5 pb-md-3">
                            <div className="py-3 mt-3 mt-md-5 text-start" style={{ lineHeight: '18px' }}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span style={{ fontSize: '20px', fontWeight: 300 }}>Shoppingmall</span></p>
                                    <span>.XYZ</span>
                                </div>
                            </div>
                            <div className="col-md-12 d-block d-md-none">
                                <div className="accordion accordion-flush" id="side-category-mobile-view">
                                    <div className="accordion-item bg-light">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat-menu-1">
                                                <span style={{ marginRight: "7px" }} > <FontAwesomeIcon icon={faShoppingCart} /></span>   Show order summary
                                            </button>
                                        </h2>
                                        <div className="mobile-category-price-show d-md-none d-block">৳ {this.props.grandTotal}</div>
                                        <div id="cat-menu-1" className="accordion-collapse collapse" data-bs-parent="#cat-menu-1">
                                            <div className="accordion-body">
                                                <ShippingAmountTotal data={this.state.cartData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="breadcrumbs category-mobile-view mb-2 mb-md-3">
                                <ul className="d-flex align-items-center m-0 p-0">
                                    <li className="me-4">
                                        <Link to="#">Cart</Link>
                                    </li>
                                    <li className="me-4 active">
                                        <Link to="#">Information</Link>
                                    </li>
                                    <li className="me-4">
                                        <Link to="#">Shipping</Link>
                                    </li>
                                    <li className="me-4">
                                        <Link to="#">Payment</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="cart-shipping-left-content">
                                <p className="top-para"> Due to constraints from our carriers because of current events, shipments may be delayed and stock may be limited.</p>
                                <div className="contact-ship-box mb-2">
                                    <div className="row border-bottom py-3">
                                        <div className="col-md-2 col-sm-12">
                                            <p className="top-para m-0">Contact</p>
                                        </div>
                                        <div className="col-md-8 col-sm-9 col-9">
                                            <p className="m-0" style={{ color: '#333' }}>{this.state.phone}</p>
                                        </div>
                                        {/* <div className="col-md-2 col-sm-3 col-3 text-end">
                                            <button className="change-btn">Change</button>
                                        </div> */}
                                    </div>
                                    <div className="row border-bottom py-2">
                                        <div className="col-md-2 col-sm-12">
                                            <p className="top-para m-0">Ship to </p>
                                        </div>
                                        <div className="col-md-8 col-sm-9 col-9">
                                            <p className="m-0" style={{ color: '#333' }}>{this.state.address}, {this.state.division}, {this.state.district}-{this.state.postCode} </p>
                                        </div>
                                        { /* <div className="col-md-2 col-sm-3 col-3 text-end">
                                            <button className="change-btn">Change</button>
                                        </div> */}
                                    </div>
                                    {/*<div className="row py-2">*/}
                                    {/*    <div className="col-md-2 col-sm-12">*/}
                                    {/*        <p className="top-para m-0">Method</p>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="col-md-10 col-sm-12">*/}
                                    {/*        <p className="m-0" style={{ color: '#333' }}>Shipping - FedEx International Economy <span style={{color:'#000'}}>৳500</span>  </p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <br />
                                <div className="payment-method-wrapper">
                                    <h1 className="shipping-method-title mb-2"> Select a payment option </h1>
                                    {  /*  <p>All transactions are secure and encrypted.</p> */}
                                    <div className="payment-method-box box">
                                        <div className="payment-method-body">
                                            <div className="row">
                                                <div className="col-md-6 mx-auto">
                                                    <div className="row">
                                                        <div className="col-6" onClick={this.getPaymentOption}>
                                                            <label className="payment_option my-4" data-toggle="tooltip" data-title="Paypal" data-original-title="" title="">
                                                                <input type="radio" id="" name="payment_option" value="cash_on_delivery" className="online_payment" checked="" />
                                                                 <span className={this.state.payment_type === "cash_on_delivery" ? "markpayment" : ""}>
                                                                <img style={{padding: "10px 0"}} src={deliveryImg} class ="img-fluid" alt="online_payment" title="online_payment"/>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="col-6" onClick={this.getPaymentOption}>
                                                            <label className="payment_option my-4" data-toggle="tooltip" data-title="Cash on Delivery" data-original-title="" title="">
                                                                <input type="radio" id="" name="payment_option" value="sslcommerz" className="online_payment" checked="" />
                                                                <span className={this.state.payment_type === "sslcommerz" ? "markpayment" : ""}>
                                                                <img style={{padding: "10px 0"}} src={onlinePayImg} class ="img-fluid" alt="online Pay image" title="online Pay image"/>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="payment-method-chk">
                                        <form>
                                            <input onChange={this.agree} type="checkbox" value="1" name="name" />
                                            <span id="agree-policies" className="chkbox-detail payment-check-mobile-view"> I agree to the <span className="sngl-condition"><TramsModal /></span> <span className="sngl-condition"><ReturnPolicy /></span> <span className="sngl-condition"><PrivacyPolicy /></span></span>
                                        </form>
                                    </div>
                                </div>
                                <br />
                                <div className="billing-address-wrapper">
                                    <h2 className="shipping-method-title mb-0">Billing address</h2>
                                    <p>Select the address that matches your card r payment omethod.</p>
                                    <div className="billing-address-form-box box">
                                        <div className="row">
                                            <div className="col-md-12 mb-2">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input onClick={this.onChangeValue} type="radio" value="1" name="bill_addr" id="customRadioInline1" className="custom-control-input" checked={this.state.billing == 1 ? true : false} />
                                                    <label className="ms-2" for="customRadioInline1" style={{ color: '#333' }}>Same as shipping address</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input onClick={this.onChangeValue} type="radio" value="2" name="bill_addr" id="customRadioInline2" className="custom-control-input" />
                                                    <label className="ms-2" for="customRadioInline2" style={{ color: '#333' }}>Use a different billing address</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.state.billingAddress1} ></div>
                                        <div className={this.state.billingAddress2}>
                                            <div className="mt-3">
                                                <form className="">
                                                    <div className="row">
                                                        <div className="col-md-6 mb-3">
                                                            <input onChange={this.getName} type="text"
                                                                className="form-control" id="name" name="firstName"
                                                                placeholder="First Name" />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <input onChange={this.getLastName} type="text"
                                                                className="form-control" id="lastName" name="lastName"
                                                                placeholder="Last Name" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <input onChange={this.getAddress} type="text"
                                                                className="form-control" id="address" name="address"
                                                                placeholder="Address" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <input onChange={this.getOtherAddress} type="text"
                                                                className="form-control" id="otherAddress" name="otherAddress"
                                                                placeholder="Apartment, suite, etc. (Optional)" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3">
                                                            <input onChange={this.getCity} type="text" className="form-control" id="city" name="city" placeholder="City" />
                                                        </div>
                                                    </div>
                                                    {/*<div className="row">*/}
                                                    {/*    <div className="col-md-6 mb-3">*/}
                                                    {/*        <select className="form-select">*/}
                                                    {/*            <option selected>Country/Region</option>*/}
                                                    {/*            <option value="1">One</option>*/}
                                                    {/*            <option value="2">Two</option>*/}
                                                    {/*            <option value="3">Three</option>*/}
                                                    {/*        </select>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="col-md-6 mb-3">*/}
                                                    {/*        <input type="text" className="form-control" name="postelCode" placeholder="Postel code" />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className="row">
                                                        <div className="col-md-12 mb-3 inp-abs-img-div">
                                                            <input onChange={this.getPhone} type="tel" className="form-control" id="phone" name="phone" placeholder="Phone" />
                                                            <img src={quesImg} alt="lock" title="lock" className="inp-abs-img" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-md-5 text-center text-sm-start order-1 order-sm-first">
                                        <div className="mt-3">
                                            {this.props.accessToken ? (<Link to="/cart-shipping" className="return-link ps-3">Return to shipping</Link>) : (<Link to="/cart-info" className="return-link ps-3">Return to Information</Link>)}
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-12 text-end">
                                        {this.state.agree ? <button onClick={this.payNow} className="payment-btn-size btn continue-btn d-block d-md-inline-block pay-now-btn-mobile-view">{this.state.payment_button_name}</button> : <button onClick={this.agreeMst} className="payment-btn-size btn continue-btn d-block d-md-inline-block pay-now-btn-mobile-view">{this.state.payment_button_name}</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5  mt-5 pt-3 ps-md-5 mb-3 d-none d-md-block">
                            <ShippingAmountTotal data={this.state.cartData} />
                        </div>
                    </div>
                </div>
                {this.onUserRedirect()}
                <Toaster position="top-right" reverseOrder={false} />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    estimateInfo: state.builder.estimateInfo,
    cartTotalItem: state.builder.cartTotalItem,
    shippingAddress: state.builder.shippingAddress,
    guestInfo: state.builder.guestInfo,
    guestId: state.builder.guestId,
    userInfo: state.builder.userInfo,
    couponDiscount: state.builder.couponDiscount,
    accessToken: state.builder.accessToken,
    grandTotal: state.builder.grandTotal,
    cart: state.builder.cart,
    division: state.builder.division,
    district: state.builder.district,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(CartPayment);