import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MobileHeader from '../Header/MobileHeader';


class UpdatePassword extends Component{
    render(){
        return(
            <div>
                <Header/>
                <MobileHeader/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto">
                            <div className="d-block d-sm-none py-5 my-4 text-center" style={{ lineHeight: '18px' }}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span style={{ fontSize: '20px', fontWeight: 300 }}>Shoppingmall</span></p>
                                    <span>.XYZ</span>
                                </div>
                            </div>
                            <div className="login-body pb-5 pt-sm-5 my-sm-5 signup-body-mobile-view">
                                <h1 className="title-h3 text-center text-uppercase">Update Password</h1>
                                <p className="text-center">Please enter your new password</p>
                                <form className="form">
                                    <div className="form-group mb-3">
                                        <input type="password" className="form-control" id="new_password" placeholder="New Password" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="password" className="form-control" id="confirm_new_password" placeholder="Confirm New Password" />
                                    </div>
                                    <div className="form-group mb-3">
                                        <Link to="/reg-success" className="btn base-button text-light d-block text-uppercase">Password Updated</Link>
                                    </div>
                                    <div className="form-group text-center">
                                        <span> Remember your password?</span> 
                                        <Link to="/login" className="create-link-txt"> Back to login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default UpdatePassword;