import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import ShippingAmountTotal from './components/ShippingAmountTotal';
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import { eshop } from "../../../redux/reducers";

class CartInfo extends Component {
    constructor() {
        super();
        this.state = {
            cartData: [],
            userIdentity: "",
            user_id: "",
            guest_id: "",
            type: "",
            email: "",
            firstName: "",
            lastName: "",
            address: "",
            otherAddress: "",
            postCode: "",
            phone: "",
            userRedirect: "",
            division: [],
            divisionId: "",
            district: [],
            districtId: "",
            checkRedirect: false,
            cartPayment: false,
        }
    }
    componentDidMount() {
        if (this.props.accessToken) {
            this.cartList(this.props.userInfo.id, "user");
            this.setState({ user_id: this.props.userInfo.id, type: "user" })
        } else {
            this.setState({ guest_id: this.props.guestId, type: "guest" })
            let estimateValue = this.props.estimateInfo
            this.setState({ address: estimateValue?.address, divisionId: estimateValue?.division, districtId: estimateValue?.district, postCode: estimateValue?.postCode })

        }

        Axios.get(ApiUrl.Division).then(response => {
            if (response.status === 200) {
                this.setState({ division: response.data.data })
                this.district(this.state.divisionId)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }
    getDivision = (event) => {
        this.setState({ divisionId: event.target.value })
        this.district(event.target.value)
        if (event.target.value) {
            document.getElementById("division").style.border = ""
        }
    }
    district = (divisionId) => {
        Axios.get(ApiUrl.District(divisionId)).then(response => {
            if (response.status === 200) {
                this.setState({ district: response.data.data })
            }
        }).catch(error => {

        })
    }
    getDistrict = (event) => {
        this.setState({ districtId: event.target.value })
        this.ShippingCost(event.target.value);
        if (event.target.value) {
            document.getElementById("district").style.border = ""
        }
    }
    ShippingCost = (divisionId) => {
        Axios.get(ApiUrl.ShippingCost(divisionId)).then(response => {
            if (response.status === 200) {
                this.props.setEstimateInfo({
                    ...this.props.estimateInfo,
                    shippingCost: response.data.data[0].shippingCost
                });
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }

    email = (event) => {
        this.setState({ email: event.target.value })
        let maliChar = event.target.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        if (maliChar == null || maliChar === "") {
            document.getElementById("mail-valid").style.border = "1px solid #f00"
            document.getElementById("eMail").innerHTML = "Type must be valid E-mail *"
        } else {
            document.getElementById("mail-valid").style.border = ""
            document.getElementById("eMail").innerHTML = ""
        }
    }
    firstName = (event) => {
        let firstNameValid = event.target.value
        if (firstNameValid == "") {
            document.getElementById("firstName-input-box").style.border = "1px solid #f00"
            document.getElementById("firstName-valid").innerHTML = "Type must be first Name *"
        } else {
            document.getElementById("firstName-input-box").style.border = ""
            document.getElementById("firstName-valid").innerHTML = ""
            this.setState({ firstName: event.target.value })
        }
        // this.setState({firstName: event.target.value})
    }
    lastName = (event) => {
        this.setState({ lastName: event.target.value })
    }
    address = (event) => {
        let addressNameValid = event.target.value
        if (addressNameValid == "") {
            document.getElementById("address-input-box").style.border = "1px solid #f00"
            document.getElementById("address-valid").innerHTML = "Type must be Address *"
        } else {
            document.getElementById("address-input-box").style.border = ""
            document.getElementById("address-valid").innerHTML = ""
            this.setState({ address: event.target.value })
        }
        // this.setState({address: event.target.value})
    }
    otherAddress = (event) => {
        this.setState({ otherAddress: event.target.value })
    }
    thana = (event) => {
        this.setState({ thana: event.target.value })
    }
    postCode = (event) => {
        let postalCode = event.target.value
        if (postalCode == "") {
            document.getElementById("postCode-input-box").style.border = "1px solid #f00"
            document.getElementById("postCode-valid").innerHTML = "Type must be post code *"
        } else {
            document.getElementById("postCode-input-box").style.border = ""
            document.getElementById("postCode-valid").innerHTML = ""
            this.setState({ postCode: event.target.value })
        }
    }
    phone = (event) => {
        let phnChar = event.target.value
        if (phnChar.length < 11 || phnChar.length == 0 || phnChar.length == "") {
            document.getElementById("phoneNumber").innerHTML = "phone number must be 11 character *"
            document.getElementById("phoneNumber").focus()
            document.getElementById("phoneNumber-input-box").style.border = "1px solid #f00"
        } else {
            document.getElementById("phoneNumber").innerHTML = ""
            document.getElementById("phoneNumber-input-box").style.border = ""
            this.setState({ phone: event.target.value })
        }
    }

    onNextClick() {
        let divisionId = this.state.divisionId;
        let districtId = this.state.districtId;
        let email = this.state.email ? this.state.email : this.props.guestInfo.email;
        let firstName = this.state.firstName ? this.state.firstName : this.props.guestInfo.firstName;
        let lastName = this.state.lastName ? this.state.lastName : this.state.lastName;
        let address = this.state.address ? this.state.address : this.props.guestInfo.address;
        let otherAddress = this.state.otherAddress ? this.state.otherAddress : this.props.guestInfo.otherAddress;
        let postCode = this.state.postCode;
        let phone = this.state.phone ? this.state.phone : this.props.guestInfo.phone;
        if (email === '' || firstName === '' || address === '' || postCode === '' || phone === '') {
            if (this.state.divisionId === "") {
                document.getElementById("division").style.border = "1px solid #f00";
            }
            if (this.state.districtId === "") {
                document.getElementById("district").style.border = "1px solid #f00";
            }
            if (email.length === 0) {
                document.getElementById("mail-valid").style.border = "1px solid #f00"
            }
            if (firstName.length === 0) {
                document.getElementById("firstName-input-box").style.border = "1px solid #f00"
            }
            if (address.length === 0) {
                document.getElementById("address-input-box").style.border = "1px solid #f00"
            }
            if (postCode.length === 0) {
                document.getElementById("postCode-input-box").style.border = "1px solid #f00"
            }
            if (phone.length === 0) {
                document.getElementById("phoneNumber-input-box").style.border = "1px solid #f00"
            }
        } else if (email === undefined && firstName === undefined && phone === undefined) {
            if (email === undefined) {
                document.getElementById("mail-valid").style.border = "1px solid #f00"
            }
            if (firstName === undefined) {
                document.getElementById("firstName-input-box").style.border = "1px solid #f00"
            }
            if (phone === undefined) {
                document.getElementById("phoneNumber-input-box").style.border = "1px solid #f00"
            }
        }else {
            let data = {
                "email": email,
                "firstName": firstName,
                "lastName": lastName,
                "address": address,
                "otherAddress": otherAddress,
                "divisionId": divisionId,
                "districtId": districtId,
                "postCode": postCode,
                "phone": phone,
            }
            // localStorage.setItem("guest_info", JSON.stringify(data))
            this.props.setGuestInfo(data);
            this.setState({ cartPayment: true })
        }

    }
    cartPaymentRedirect = () => {
        if (this.state.cartPayment === true) {
            return <Redirect to={"/cart-payment"} />
        }
    }

    login = () =>{
        this.props.setPreviousUrl(window.location.pathname);
        this.setState({checkRedirect: true})
        console.log("(window.location.pathname", window.location.pathname)
        this.onUserRedirect()
    }
    onUserRedirect = () => {
        if (this.state.checkRedirect == true) {
            return <Redirect to='/login'/>
        }
    }

    render() {
        const myDivision = this.state.division.map((division, i) => {
            return (
                <option selected={this.state.divisionId == division.id ? "selected" : ""} key={i} value={division.id}>{division.name}</option>
            )
        })
        const myDistrict = this.state.district.map((district, i) => {
            return (
                <option selected={this.state.districtId == district.id ? "selected" : ""} key={i} value={district.id}>{district.name}</option>
            )
        })
        return (
            <div className="">
                <Header />
                <MobileHeader />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 pe-md-5 mb-3 mb-md-5 pb-md-3">
                            <div className="py-3 mt-3 mt-md-5 text-start" style={{ lineHeight: '18px' }}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span style={{ fontSize: '20px', fontWeight: 300 }}>Shoppingmall</span></p>
                                    <span>.XYZ</span>
                                </div>
                            </div>
                            <div className="col-md-12 d-block d-md-none">
                                <div className="accordion accordion-flush" id="side-category-mobile-view">
                                    <div className="accordion-item bg-light">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#cat-menu-1">
                                                <span style={{ marginRight: "7px" }} > <FontAwesomeIcon icon={faShoppingCart} /></span>   Show order summary
                                            </button>
                                        </h2>
                                        <div className="mobile-category-price-show d-md-none d-block">৳ {this.props.grandTotal}</div>
                                        <div id="cat-menu-1" className="accordion-collapse collapse" data-bs-parent="#cat-menu-1">
                                            <div className="accordion-body">
                                                <ShippingAmountTotal data={this.state.cartData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="breadcrumbs category-mobile-view mb-2 mb-md-3">
                                <ul className="d-flex align-items-center m-0 p-0">
                                    <li className="me-4">
                                        <Link to="#">Cart</Link>
                                    </li>
                                    <li className="me-4 active">
                                        <Link to="#">Information</Link>
                                    </li>
                                    <li className="me-4">
                                        <Link to="#">Shipping</Link>
                                    </li>
                                    <li className="me-4">
                                        <Link to="#">Payment</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="cart-shipping-left-content">
                                <p className="top-para"> Due to constraints from our carriers because of current events, shipments may be delayed and stock may be limited.</p>
                                <div>
                                    <div className="d-flex justify-content-between flex-wrap mb-2">
                                        <h1 className="contact-info-title mb-0">Contact information</h1>
                                        {/*<span className="acc-success-msg">Already have an account?<span style={{cursor: "pointer"}} onClick={this.login} className="login-link"> Log in</span></span>*/}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <input onChange={this.email} required id="mail-valid" type="email" defaultValue={this.props.guestInfo.email} className="form-control" name="email" placeholder="Email" />
                                            <span style={{ color: 'red' }} id="eMail"></span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="ship-cancel-info">We are unable to ship to a PO box. Please note that if you enter a PO box, your order will be canceled.</p>
                                        </div>
                                    </div>
                                </div>

                                <form className="">
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input onChange={this.firstName} type="text" id="firstName-input-box" className="form-control" defaultValue={this.props.guestInfo.firstName} name="firstName" placeholder="First Name" />
                                            <span style={{ color: 'red' }} id="firstName-valid"></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <input onChange={this.lastName} type="text" defaultValue={this.props.guestInfo.lastName} className="form-control" name="lastName" placeholder="Last Name" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <input onChange={this.address} value={this.state.address} type="text" defaultValue={this.props.guestInfo.address} id="address-input-box" className="form-control" name="address" placeholder="Address" />
                                        </div>
                                        <span style={{ color: 'red' }} id="address-valid"></span>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <input onChange={this.otherAddress} type="text" defaultValue={this.props.guestInfo.otherAddress} className="form-control" name="otherAddress" placeholder="Apartment, suite, etc. (Optional)" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <select onChange={this.getDivision} className="form-select select-division" id="division">
                                                <option value="" className="active">Select division</option>
                                                {myDivision}
                                            </select>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <select onChange={this.getDistrict} className="form-select select-division" id="district">
                                                <option value="">Select district</option>
                                                {myDistrict}
                                            </select>
                                        </div>
                                        <span style={{ color: 'red' }} id="city-valid"></span>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <input onChange={this.phone} id="phoneNumber-input-box" type="tel" defaultValue={this.props.guestInfo.phone} className="form-control" name="phone" placeholder="Phone" />
                                            <span style={{ color: 'red' }} id="phoneNumber"></span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <input onChange={this.postCode} value={this.state.postCode} type="text" defaultValue={this.props.guestInfo.postCode} id="postCode-input-box" className="form-control" name="postelCode" placeholder="Postel code" />
                                            <span style={{ color: 'red' }} id="postCode-valid"></span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-5 text-center text-sm-start order-1 order-sm-first">
                                            <div className="mt-3">
                                                <Link className="return-link ps-3" to="/product-cart">Return to cart</Link>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 text-end">
                                            <span onClick={() => { this.onNextClick() }} className="btn continue-btn d-block d-md-inline-block">Continue to Payment</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5  mt-5 pt-3 ps-md-5 mb-3 d-none d-md-block">
                            <ShippingAmountTotal data={this.state.cartData} />
                        </div>
                    </div>
                </div>
                {this.onUserRedirect()}
                {this.cartPaymentRedirect()}
                <Toaster position="top-right" reverseOrder={false} />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    estimateInfo: state.builder.estimateInfo,
    userInfo: state.builder.userInfo,
    guestId: state.builder.guestId,
    guestInfo: state.builder.guestInfo,
    previousUrl: state.builder.previousUrl,
    grandTotal: state.builder.grandTotal,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(CartInfo);