import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 3px 10px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #D8DBDD;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 9;
    padding-left: 20px;
    li {
      color: #fff;
    }
  }
`;

const MobileCategory = ({ open, openTab }) => {
  return (
    <Ul open={open}>
      {window.location.pathname === "/buyer-profile" ? <div><div className="back-home-main">
        <Link to="/" className="back-home-btn">TRAVELSHOP <span className="back-home-icon"> <FontAwesomeIcon icon={faAngleRight} /></span></Link>
      </div>
        <div className="buyer-profile-wrap">
          <div className="buyer-profile-main">
            <div className="mobile-buyer-profile-category">
              <ul className="nav nav-pills mobile-category-list" id="pills-tab" role="tablist">
                {/*<li className="nav-item d-flex justify-content-between" role="presentation">*/}
                {/*  <button className="nav-link category-item" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="false">WALLET</button>*/}
                {/*  <span> &#5171;</span>*/}
                {/*</li>*/}
                <li onClick={() => { openTab() }} className="nav-item d-flex justify-content-between" role="presentation">
                  <button className="nav-link category-item" id="pills-my-order-tab" data-bs-toggle="pill" data-bs-target="#pills-my-order" type="button" role="tab" aria-controls="pills-my-order" aria-selected="false">MY ORDERS </button>
                  <span> &#5171;</span>
                </li>
                <li onClick={() => { openTab() }} className="nav-item d-flex justify-content-between" role="presentation">
                  <button className="nav-link category-item" id="pills-wishlist-tab" data-bs-toggle="pill" data-bs-target="#pills-wishlist" type="button" role="tab" aria-controls="pills-wishlist" aria-selected="false">WISHLIST</button>
                  <span> &#5171;</span>
                </li>
                {/*<li className="nav-item d-flex justify-content-between" role="presentation">*/}
                {/*  <button className="nav-link category-item" id="pills-message-tab" data-bs-toggle="pill" data-bs-target="#pills-message" type="button" role="tab" aria-controls="pills-message" aria-selected="false">MESSAGE</button>*/}
                {/*  <span> &#5171;</span>*/}
                {/*</li>*/}
                <li onClick={() => { openTab() }} className="nav-item" role="presentation">
                  <button className="nav-link category-item" id="pills-personal-info-tab" data-bs-toggle="pill" data-bs-target="#pills-personal-info"  type="button" role="tab" aria-controls="pills-personal-info"
                          aria-selected="true">PERSONAL INFORMATION
                  </button>
                </li>
                {/*<li className="nav-item d-flex justify-content-between" role="presentation">*/}
                {/*  <button className="nav-link category-item" id="pills-affiliate-tab" data-bs-toggle="pill" data-bs-target="#pills-affiliate" type="button" role="tab" aria-controls="pills-affiliate" aria-selected="false">AFFILIATE</button>*/}
                {/*  <span> &#5171;</span>*/}
                {/*</li>*/}
                <li onClick={() => { openTab() }} className="nav-item d-flex justify-content-between" role="presentation">
                  <button className="nav-link category-item" id="pills-promo-code-tab" data-bs-toggle="pill" data-bs-target="#pills-promo-code" type="button" role="tab" aria-controls="pills-promo-code" aria-selected="false">PROMO CODE</button>
                  <span> &#5171;</span>
                </li>
              </ul>
            </div>
          </div>
        </div></div> : <div className="back-home-main">
        <Link to="/" className="back-home-btn">TRAVELSHOP <span className="back-home-icon"> <FontAwesomeIcon icon={faAngleRight} /></span></Link>
      </div>}

    </Ul>
  )
}

export default MobileCategory;