import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MobileHeader from '../Header/MobileHeader';
import toast, {Toaster} from "react-hot-toast";
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";

class Signup extends Component {
    constructor() {
        super();
        this.state = {
            btn: "Registered",
            UserRedirect: "",
            firstName: "",
            lastName: "",
            email: "",
            passwordUser: "",
            mobile: LocalStorageHelper.getUserMobile(),
            otpCode: LocalStorageHelper.getOptCode()
        }
        this.firstOnChange = this.firstOnChange.bind(this)
        this.lastOnChange = this.lastOnChange.bind(this)
        this.emailOnChange = this.emailOnChange.bind(this)
        this.passwordOnChange = this.passwordOnChange.bind(this)
        this.onUserRedirect = this.onUserRedirect.bind(this)
    }

    firstOnChange = (even) => {
        let firstName = even.target.value
        if (firstName) {
            this.setState({firstName: firstName})
        } else {
            this.setState({firstName: ""})
        }
    }
    lastOnChange = (even) => {
        let lastName = even.target.value
        if (lastName) {
            this.setState({lastName: lastName})
        } else {
            this.setState({lastName: ""})
        }
    }
    emailOnChange = (even) => {
        let email = even.target.value
        if (email) {
            this.setState({email: email})
        } else {
            this.setState({email: ""})
        }
    }
    passwordOnChange = (even) => {
        let passwordUser = even.target.value
        if (passwordUser) {
            this.setState({passwordUser: passwordUser})
        } else {
            this.setState({passwordUser: ""})
        }
    }

    onUserRedirect() {
        if (this.props.accessToken || this.state.UserRedirect === true || this.props.userInfo.id != null) {
            return <Redirect to={"/reg-success"}/>
        }
    }

    onSubmit = () => {
        let firstName = this.state.firstName
        let lastName = this.state.lastName
        let email = this.state.email
        let passwordUser = this.state.passwordUser
        let mobile = this.state.mobile
        let otpCode = this.state.otpCode

        if (firstName.length === 0) {
            toast.error("First name required")
        }
        if (lastName.length === 0) {
            toast.error("Last name required")
        }
        if (email.length === 0) {
            toast.error("Email required")
        }
        if (passwordUser.length === 0) {
            toast.error("Password required")
        } else if (passwordUser.length < 7) {
            toast.error("Password must be at least 8 characters")
        } else {
            this.setState({btn: LocalStorageHelper.loadingBtn()})
            let myFormData = new FormData();
            myFormData.append("first_name", firstName);
            myFormData.append("last_name", lastName);
            myFormData.append("email", email);
            myFormData.append("password", passwordUser);
            myFormData.append("phone", mobile);
            myFormData.append("otpCode", otpCode);
            Axios.post(ApiUrl.Signup, myFormData).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    this.setState({UserRedirect: true});
                    this.props.setAccessToken(response.data.access_token)
                    this.props.setUserInfo(response.data.user)
                } else {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        }
    }

    render() {
        return (
            <div>
                <Header/>
                <MobileHeader/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto">
                            <div className="d-block d-sm-none py-4 my-4 text-center" style={{lineHeight: '18px'}}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span
                                        style={{fontSize: '20px', fontWeight: 300}}>Shoppingmall</span></p>
                                    <span>.XYZ</span>
                                </div>
                            </div>
                            <div className="login-body pb-5 pt-sm-5 my-sm-5">
                                <h1 className="title-h3 text-center text-uppercase">Register</h1>
                                <p className="text-center">Please fill in the information below:</p>
                                <form className="form">
                                    <div className="form-group mb-3">
                                        <input onChange={this.firstOnChange} type="text" autoComplete='off' className="form-control"
                                               id="fname" placeholder="First Name"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={this.lastOnChange} type="text" autoComplete='off' className="form-control"
                                               id="lname" placeholder="Last Name"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={this.emailOnChange} type="email" autoComplete='off' className="form-control"
                                               id="lname" placeholder="Email"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input onChange={this.passwordOnChange} type="password" autoComplete='off' className="form-control"
                                               id="password" placeholder="Password"/>
                                    </div>
                                    {/*<div className="form-group mb-3 text-center">*/}
                                    {/*    <p>I’m a</p>*/}
                                    {/*</div>*/}
                                    <div className="form-group mb-3 buyer-seller-reg-tab">
                                        {/*<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">*/}
                                        {/*    <li className="nav-item" role="presentation">*/}
                                        {/*        <button className="nav-link text-center active" id="pills-buyer-tab" data-bs-toggle="pill" data-bs-target="#buyer-tab" type="button" role="tab" aria-controls="pills-buyer" aria-selected="true">Buyer (Customer)</button>*/}
                                        {/*    </li>*/}
                                        {/*    <li className="nav-item" role="presentation">*/}
                                        {/*        <button className="nav-link text-center" id="pills-seller-tab" data-bs-toggle="pill" data-bs-target="#seller-tab" type="button" role="tab" aria-controls="pills-seller" aria-selected="false">Seller (Shopkeeper)</button>*/}
                                        {/*    </li>*/}
                                        {/*</ul>*/}
                                        <div className="tab-content" id="pills-tabContent">
                                            {/*<div className="tab-pane fade show active" id="buyer-tab" role="tabpanel" aria-labelledby="pills-buyer-tab"></div>*/}
                                            <div className="tab-pane fade" id="seller-tab" role="tabpanel"
                                                 aria-labelledby="pills-seller-tab">
                                                <div className="form-group mb-3">
                                                    <select className="form-select" id="division_select">
                                                        <option selected>Select Division</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <select className="form-select" id="district_select">
                                                        <option selected>Select District</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-3">
                                                    <select className="form-select" id="thana_select">
                                                        <option selected>Select Thana</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <span onClick={this.onSubmit}
                                              className="btn base-button text-light d-block text-uppercase reg-btn">Register Now</span>
                                    </div>
                                    <div className="form-group text-center">
                                        {/* <span> Already have an account?</span> */}
                                        <a href="/login" className="create-link-txt"> Back to login</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster position="top-right" reverseOrder={false}/>
                {this.onUserRedirect()}
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    setUserInfo: state.builder.setUserInfo,
    accessToken: state.builder.accessToken,
    setAccessToken: state.builder.setAccessToken,
    userInfo: state.builder.userInfo,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(Signup);