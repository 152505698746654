import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import ShippingAmountTotal from './components/ShippingAmountTotal';
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios";
import toast from "react-hot-toast";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Modal } from "react-bootstrap";
import AddNewAddress from "../BuyerProfile/components/AddNewAddress";
import { eshop } from "../../../redux/reducers";
import { connect } from "react-redux";

class CartShipping extends Component {
    state = {
        cartData: [],
        userIdentity: "",
        user_id: "",
        guest_id: "",
        type: "",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        otherAddress: "",
        city: "",
        thana: "",
        postCode: "",
        phone: "",
        sPrice: 0,
        grandTotal: LocalStorageHelper.getItem("grand_total"),
        userInfo: this.props.userInfo,
        addAddressShow: false,
        showAddress: false,
        token: this.props.accessToken,
        myAddressData: [],
    }
    userLoad = false;
    componentDidMount() {
        // this.props.setShippingAddress({})
    }
    loadAddress = () => {
        if (this.props.accessToken) {
            this.setState({ user_id: this.props.userInfo.id, type: "user" })
            let divisions = JSON.parse(this.props.division);
            let districts = JSON.parse(this.props.district);
            let estimatAddress = {
                address: this.props.estimateInfo.address,
                divisionId: divisions.find(item => item.id == this.props.estimateInfo.division).name,
                districtId: districts.find(item => item.id == this.props.estimateInfo.district).name,
                postal_code: this.props.estimateInfo.postCode,
                date: this.props.estimateInfo.date,
                time: this.props.estimateInfo.time,
                shippingCost: this.props.estimateInfo.shippingCost,
                user_id: this.props.userInfo.id,
                phone: this.props.userInfo.phone,
                firstName: this.props.userInfo.name,
            }

            this.props.setShippingAddress(estimatAddress)
            setTimeout(() => {
                this.myAddressList(this.props.userInfo.id);
            }, 2000)
        } else {
            this.setState({ guest_id: this.props.guestId, type: "guest" })
        }
    }


    addressListClose = () => {
        this.setState({ ...this.state, showAddress: false })
    };
    addressListShow = () => {
        this.setState({ ...this.state, showAddress: true })
    };
    myAddressList = (userId) => {
        Axios.get(ApiUrl.MyAddress(userId), { headers: { 'Authorization': `Bearer  ${this.state.token}` } }).then(response => {
            if (response.data.data.length === 0) {
                this.setState({ ...this.state, showAddress: false, addAddressShow: true });
            } else {
                this.setState({ ...this.state, myAddressData: response.data.data });
            }
        }).catch(error => {

        })
    }
    addShippingAddress = () => {
        this.myAddressList(this.props.userInfo.id);
    }
    setDefault = (id) => {
        Axios.get(ApiUrl.SetdefaultShippingAddress(this.props.userInfo.id, id), { headers: { 'Authorization': `Bearer  ${this.state.token}` } }).then(response => {
            if (response.status === 200) {
                this.addressListClose()
                this.shippingCost(response.data.districtId)
                this.addressSetDefault(this.props.userInfo.id)
            }
        }).catch(error => {

        })
    }
    shippingCost = (districtId) => {
        Axios.get(ApiUrl.ShippingCost(districtId)).then(response => {
            if (response.status === 200) {
                this.props.setEstimateInfo({
                    ...this.props.estimateInfo,
                    shippingCost: response.data.data[0].shippingCost
                })
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }

    addressSetDefault = (user_id) => {
        Axios.get(ApiUrl.addressSetDefault(user_id), { headers: { 'Authorization': `Bearer  ${this.state.token}` } }).then(response => {
            if (response.status == 200) {
                if (response.data.data) {
                    this.props.setShippingAddress(response.data.data)
                }
            }
        }).catch(error => {
            console.log("Request failed ! try again addressSetDefault")
        })
    }

    handleShow = () => {
        this.setState({ ...this.state, showAddress: false, addAddressShow: true });
    }
    handleClose = () => {
        this.setState({ ...this.state, showAddress: true, addAddressShow: false });
    }

    render() {
        if (Object.keys(this.props.userInfo).length > 0 && this.userLoad === false) {
            this.loadAddress();
            this.userLoad = true;
        }
        const add = this.props.shippingAddress;
        let address = '';
        let addId = add.id;
        let contact = add.phone;
        if (add.address) {
            address += add.address
        }
        if (add.otherAddress) {
            address += ", " + add.otherAddress
        }
        address += ", " + add.divisionId + ", " + add.districtId + "-" + add.postal_code
        return (
            <Fragment>
                <Modal id="addressList" show={this.state.showAddress} onHide={this.addressListClose}
                    animation={false}>
                    <Modal.Header closeButton style={{ border: 'none' }}>
                    </Modal.Header>
                    <Modal.Title>
                    </Modal.Title>
                    <Modal.Body>
                        <div className="row">
                            {this.state.myAddressData.map((value, i) => {
                                return (
                                    <div onClick={() => {
                                        this.setDefault(value.id)
                                    }} key={i} className="col-md-6">
                                        <div key={i} className="change-buyer-other-info">
                                            <div
                                                htmlFor={value.lastName + value.id}
                                                className="change-add-other-info"
                                                id={value.id == addId ? "address-active" : ""}>
                                                <p className="other-info-name"
                                                    style={{ margin: "0px" }}>{value.firstName} {value.lastName}</p>
                                                <p style={{ margin: "0px" }}>{value.address} {value.otherAddress},{value.divisionId}</p>
                                                <p>{value.districtId} -{value.postal_code}</p>
                                                <p>{value.phone}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ border: 'none' }} className="justify-content-center">
                        <button className="btn btn-dark" onClick={() => {
                            this.setState({ ...this.state, addAddressShow: !this.state.addAddressShow })
                        }}>ADD A NEW ADDRESS
                        </button>
                    </Modal.Footer>
                </Modal>
                <AddNewAddress addAddress={this.addShippingAddress} addAddressShow={this.state.addAddressShow}
                    handleClose={this.handleClose} addressListClose={this.addressListClose} />
                <Header />
                <MobileHeader />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 pe-md-5 mb-3 mb-md-5 pb-md-3">
                            <div className="py-3 mt-3 mt-md-5 text-start" style={{ lineHeight: '18px' }}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span style={{
                                        fontSize: '20px',
                                        fontWeight: 300
                                    }}>Shoppingmall{this.getSubTotal}</span></p>
                                    <span>.XYZ </span>
                                </div>
                            </div>
                            <div className="col-md-12 d-block d-md-none">
                                <div className="accordion accordion-flush" id="side-category-mobile-view">
                                    <div className="accordion-item bg-light">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#cat-menu-1">
                                                <span style={{ marginRight: "7px" }}> <FontAwesomeIcon
                                                    icon={faShoppingCart} /></span> Show order summary
                                            </button>
                                        </h2>
                                        <div
                                            className="mobile-category-price-show d-md-none d-block">৳ {this.props.grandTotal}</div>
                                        <div id="cat-menu-1" className="accordion-collapse collapse"
                                            data-bs-parent="#cat-menu-1">
                                            <div className="accordion-body">
                                                <ShippingAmountTotal data={this.state.cartData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="breadcrumbs category-mobile-view mb-2 mb-md-3">
                                <ul className="d-flex align-items-center m-0 p-0">
                                    <li className="me-4">
                                        <Link to="#">Cart</Link>
                                    </li>
                                    <li className="me-4">
                                        <Link to="#">Information</Link>
                                    </li>
                                    <li className="me-4 active">
                                        <Link to="#">Shipping</Link>
                                    </li>
                                    <li className="me-4">
                                        <Link to="#">Payment</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="cart-shipping-left-content">
                                <p className="top-para"> Due to constraints from our carriers because of current events,
                                    shipments may be delayed and stock may be limited.</p>
                                <div className="contact-ship-box mb-3">
                                    <div className="row border-bottom py-3">
                                        <div className="col-md-2 col-sm-12">
                                            <p className="top-para m-0">Contact</p>
                                        </div>
                                        <div className="col-md-8 col-sm-9 col-9">
                                            <p className="m-0" style={{ color: '#333' }}>{contact}</p>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-md-2 col-sm-12">
                                            <p className="top-para m-0">Ship to</p>
                                        </div>
                                        <div className="col-md-8 col-sm-9 col-9">
                                            {address}
                                        </div>
                                        <div className="col-md-2 col-sm-3 col-3 text-end">
                                            <button className="change-btn">
                                                <div className="change-address-btn" onClick={() => {
                                                    this.addressListShow()
                                                }}>
                                                    Change
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-5 text-center text-sm-start order-1 order-sm-first">
                                        <div className="mt-3">
                                            <Link className="return-link ps-3" to="/product-cart">Return to
                                                Cart</Link>
                                        </div>
                                    </div>
                                    <div className="col-sm-7 text-end">
                                        <Link
                                            className="payment-btn-size btn continue-btn d-block d-md-inline-block"
                                            to="/cart-payment">Continue to payment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5  mt-5 pt-3 ps-md-5 mb-3 d-none d-md-block">
                            <ShippingAmountTotal data={this.state.cartData} />
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}


const mapStateToProps = state => ({
    shippingAddress: state.builder.shippingAddress,
    userInfo: state.builder.userInfo,
    guestId: state.builder.guestId,
    estimateInfo: state.builder.estimateInfo,
    accessToken: state.builder.accessToken,
    grandTotal: state.builder.grandTotal,
    division: state.builder.division,
    district: state.builder.district,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(CartShipping);