import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";



export const actionTypes = {
    SetCannedMessage: "eshop/SET_CANNED_MESSAGE",
    SetCart: "eshop/SET_CART",
    SetEstimateInfo: "eshop/SET_ESTIMATE_INFO",
    SetCartTotalItem: "eshop/SET_CART_TOTALITY",
    SetShippingAddress: "eshop/SET_SHIPPING_ADDRESS",
    SetCatSlug: "eshop/SET_CAT_SLUG",
    SetUserInfo: "eshop/SET_USER_INFO",
    SetGuestId: "eshop/SET_GUEST_ID",
    SetGuestInfo: "eshop/SET_GUEST_INFO",
    SetCouponDiscount: "eshop/SET_COUPON_DISCOUNT",
    SetAccessToken: "eshop/SET_ACCESS_TOKEN",
    SetPreviousUrl: "eshop/SET_PREVIOUS_URL",
    SetCatId: "eshop/SET_CAT_ID",
    SetSubCatId: "eshop/SET_SUB_CAT_ID",
    SetGrandTotal: "eshop/SET_GRAN_TOTAL",
    SetSubTotal: "eshop/SET_SUT_TOTAL",
    SetGeneralSettings: "eshop/SET_GEN_SETTINGS",
    SetDivision: "eshop/SET_DIVISION",
    SetDistrict: "eshop/SET_DISTRICT",
};

const initialState = {
    cannedMessage: [],
    cart: [],
    estimateInfo: {},
    cartTotalItem: 0,
    shippingAddress: {},
    catSlug: "",
    userInfo: {},
    guestId: "",
    guestInfo: {},
    couponDiscount: {},
    accessToken: "",
    previousUrl: "",
    catId: "",
    subCatId: "",
    grandTotal: "",
    subTotal: "",
    generalSettings: {},
    division: [],
    district: [],
};

export const reducer = persistReducer(
    {
        storage,
        key: "traveler-shop",
        //blacklist: ["htmlClassServiceObjects", "socket"]
    },
    (state = initialState, {type, payload}) => {
        switch (type) {

            case actionTypes.SetCannedMessage:
                return {...state, cannedMessage: payload};
            case actionTypes.SetCart:
                return {...state, cart: payload};
            case actionTypes.SetEstimateInfo:
                return {...state, estimateInfo: payload};
            case actionTypes.SetCartTotalItem:
                return {...state, cartTotalItem: payload};
            case actionTypes.SetShippingAddress:
                return {...state, shippingAddress: payload};
            case actionTypes.SetCatSlug:
                return {...state, catSlug: payload};
            case actionTypes.SetUserInfo:
                return {...state, userInfo: payload};
            case actionTypes.SetGuestId:
                return {...state, guestId: payload};
            case actionTypes.SetGuestInfo:
                return {...state, guestInfo: payload};
            case actionTypes.SetCouponDiscount:
                return {...state, couponDiscount: payload};
            case actionTypes.SetAccessToken:
                return {...state, accessToken: payload};
            case actionTypes.SetPreviousUrl:
                return {...state, previousUrl: payload};
            case actionTypes.SetCatId:
                return {...state, catId: payload};
            case actionTypes.SetSubCatId:
                return {...state, subCatId: payload};
            case actionTypes.SetGrandTotal:
                return {...state, grandTotal: payload};
            case actionTypes.SetSubTotal:
                return {...state, subTotal: payload};
            case actionTypes.SetGeneralSettings:
                return {...state, generalSettings: payload};
            case actionTypes.SetDivision:
                return {...state, division: payload};
            case actionTypes.SetDistrict:
                return {...state, district: payload};



            default:
                return state;
        }
    }
);

export const actions = {
    setCannedMessage: payload => ({payload, type: actionTypes.SetCannedMessage}),
    setCart: payload => ({payload, type: actionTypes.SetCart}),
    setEstimateInfo: payload => ({payload, type: actionTypes.SetEstimateInfo}),
    setCartTotalItem: payload => ({payload, type: actionTypes.SetCartTotalItem}),
    setShippingAddress: payload => ({payload, type: actionTypes.SetShippingAddress}),
    setCatSlug: payload => ({payload, type: actionTypes.SetCatSlug}),
    setUserInfo: payload => ({payload, type: actionTypes.SetUserInfo}),
    setGuestId: payload => ({payload, type: actionTypes.SetGuestId}),
    setGuestInfo: payload => ({payload, type: actionTypes.SetGuestInfo}),
    setCouponDiscount: payload => ({payload, type: actionTypes.SetCouponDiscount}),
    setAccessToken: payload => ({payload, type: actionTypes.SetAccessToken}),
    setPreviousUrl: payload => ({payload, type: actionTypes.SetPreviousUrl}),
    setCatId: payload => ({payload, type: actionTypes.SetCatId}),
    setSubCatId: payload => ({payload, type: actionTypes.SetSubCatId}),
    setGrandTotal: payload => ({payload, type: actionTypes.SetGrandTotal}),
    setSubTotal: payload => ({payload, type: actionTypes.SetSubTotal}),
    setGeneralSettings: payload => ({payload, type: actionTypes.SetGeneralSettings}),
    setDivision: payload => ({payload, type: actionTypes.SetDivision}),
    setDistrict: payload => ({payload, type: actionTypes.SetDistrict}),

};
