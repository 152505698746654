import LocalStorageHelper from "../LocalStorageHelper/LocalStorageHelper";
import Axios from "axios";
import toast from "react-hot-toast";
import {connect} from "react-redux";
import {eshop} from "../redux/reducers";

class ApiUrl {
    static BaseURL="https://backend.travelershopbd.com/api/v1/"
    // static  BaseURL="http://eshoppingmall.ra/api/v1/"


    static  Visitor = this.BaseURL + "visitor"
    // signup
    static  Categories = this.BaseURL + "categories"
    static  OtpCreate = this.BaseURL + "otp-create"
    static  OtpVarify = this.BaseURL + "otp-verify"
    static  ForgotPassword = this.BaseURL + "forgot-password"
    static  Signup = this.BaseURL + "auth/signup"
    static  Login = this.BaseURL + "auth/login"
    // slider
    static  BannarSlider = this.BaseURL + "sliders"
    // product
    static  homeProduct = this.BaseURL + "products/home"
    static  NewArrivalProduct = this.BaseURL + "products/new-arrival"
    static  UpCommingProduct = this.BaseURL + "products/up-comming"

    static  ProductFilter = this.BaseURL + "ajax-search"
    static  Profile = this.BaseURL + "auth/user"
    static  ChangePassword = this.BaseURL + "change-password"
    static  ProfileUpdate = this.BaseURL + "customer-profile-update"
    static  UpdateForgottenPassword = this.BaseURL + "update-forgotten-password"
    static  CustomerImage = this.BaseURL + "customer/update-image"
    static  AddWishListProduct = this.BaseURL + "wishlists"
    static  CouponApply = this.BaseURL + "coupon/apply"
    static  AddShippingAddress = this.BaseURL + "addresses/store"
    static  UpdateShippingAddress = this.BaseURL + "addresses/update"
    static  ProductVariant = this.BaseURL + "products/variant/price"
    static  generalSettings = this.BaseURL + "general-settings"
    

    static wishlistsAlreadyAdd(user_id, product_id) {
        return this.BaseURL + "wishlists/already/" + user_id + "/" + product_id
    }

    static SetdefaultShippingAddress(user_id, id) {
        return this.BaseURL + "addresses/set_default/" + user_id + "/" + id
    }

    static addressSetDefault(user_id) {
        return this.BaseURL + "addresses/default_address/" + user_id
    }

    static customerProfile(user_id) {
        return this.BaseURL + "customer-profile/" + user_id
    }

    static CategoryByProduct(slug) {
        return this.BaseURL + "products-by-category/" + slug
    }

    static loadMore(number) {
        return `${this.BaseURL}ajax-search?page=${number}`
    }

    static SubCategoryByProduct(slug) {
        return this.BaseURL + "product-by-subcategory/" + slug
    }

    static SubCategory(catId) {
        return this.BaseURL + "sub-categories/" + catId
    }

    static ProductDetails(slug) {
        return this.BaseURL + "products/details/" + slug
    }

    static purchaseHistoryDetails(code) {
        return this.BaseURL + "purchase-history-details/" + code
    }

    static WishList(userId) {
        return this.BaseURL + "wishlists/" + userId
    }

    static PromoCode(userId) {
        return this.BaseURL + "coupon-usages/" + userId
    }

    static RemoveWishList(userId) {
        return this.BaseURL + "wishlists/" + userId
    }

    static MyOrder(userId) {
        return this.BaseURL + "purchase-history/" + userId
    }

    static MyAddress(userId) {
        return this.BaseURL + "addresses/" + userId
    }

    static EditAddress(userId) {
        return this.BaseURL + "addresses/" + userId + "/edit"
    }

    static RemoveMyAddress(addressId) {
        return this.BaseURL + "addresses/destroy/" + addressId
    }

    // cart
    static addCart = this.BaseURL + "carts/add"
    static destroyCart = this.BaseURL + "carts/destroy"

    static cartList(id, type) {
        return this.BaseURL + `carts/${id}/${type}`
    }

    static userTransfer(guest_id, user_id, type) {
        return this.BaseURL + `cart/user-transfer/${guest_id}/${user_id}/${type}`
    }

    static  orderStore = this.BaseURL + "order/store"

    static cartCount(id, type) {
        return this.BaseURL + `cart/count/${id}/${type}`
    }

    static changeQuantity = this.BaseURL + "carts/change-quantity"
    static  recentlyViewedStore = this.BaseURL + "recently-viewed/store"

    static recentlyViewed(id, type) {
        return this.BaseURL + `recently-viewed/${id}/${type}`
    }

    static SslcommerzPay = this.BaseURL + "sslcommerz/pay"

// image show section
    static showImage(imgName) {
        // return "http://eshoppingmall.ra/public/"+imgName
        return "https://backend.travelershopbd.com/public/"+imgName
    }

    // check login
    static isLogin() {
        let userIdentity = []
        if (LocalStorageHelper.getItem('user_info')) {
            let userInfo = JSON.parse(LocalStorageHelper.getItem('user_info'));
            userIdentity['type'] = "user"
            userIdentity['guest_id'] = ""
            userIdentity['user_id'] = userInfo.id
            localStorage.removeItem("guest_id");
        } else {
            userIdentity['type'] = "guest"
            userIdentity['user_id'] = ""
            userIdentity['guest_id'] = LocalStorageHelper.getItem('guest_id')
            if (LocalStorageHelper.getItem("guest_id") == null) {
                LocalStorageHelper.setItem("guest_id", LocalStorageHelper.sessionId("Eshopping"))
            }
        }
        return userIdentity;
    }

    // static async loginCheck() {
    //     return new Promise(async (resolve, reject) => {
    //         let token = LocalStorageHelper.getItem('access_token');
    //         if (token) {
    //             await Axios.get(ApiUrl.Profile, {headers: {'Authorization': `Bearer  ${token}`}}).then(response => {
    //                 resolve({checkLogin: true}) ;
    //             }).catch(error => {
    //                 if (error.response.status === 401) {
    //                     localStorage.removeItem("access_token");
    //                     this.props.setUserInfo({});
    //                     window.open("/login", "_self");
    //                 } else {
    //                     toast.error("Request failed ! try again");
    //                 }
    //                 reject(error.response);
    //             })
    //         } else {
    //             resolve({checkLogin: false}) ;
    //         }
    //     });
    // }
    static pageRefreshFun(pageRefresh) {
        if (pageRefresh === true) {
            let URL = window.location;
            window.open(URL, "_self");
        }
    }

    // outer api
    static  Countries = "https://countriesnow.space/api/v0.1/countries/info?returns=currency,flag,unicodeFlag,dialCode"
    static Division = this.BaseURL + "division"

    static District(id) {
        return this.BaseURL + "district/" + id
    }

    static Upazila(id) {
        return this.BaseURL + "upazila/" + id
    }

    static ShippingCost(id) {
        return this.BaseURL + "shipping-cost/" + id
    }

    // page
    static Faqs = this.BaseURL + "faqs"
    static PrivacyPolicy = this.BaseURL + "privacy-policy"
    static TermsCondition = this.BaseURL + "terms-condition"
    static SearchProduct = this.BaseURL + "products/search-product"
/*
    let myFormData = new FormData();
    myFormData.append("phone", mobile);
    Axios.post(ApiUrl.OtpVarify,myFormData).then((response)=>{
    if(response.status===200 && response.data.status===1){
    toast.success("Verification code has been sent")
    this.setState({UserRedirect:true});
}else if(response.status===200 && response.data.status===0){
    response.data.message.map(error=>{
         this.setState({myData:response.data.data})
    })
    this.setState({btn:"Continue"})
}
}).catch(function(){
    toast.error("Request failed ! try again");
    this.setState({btn:"Continue"})
})


    Axios.get(ApiUrl.CategoryByProduct(slug)).then(response=>{
    if(response.status==200){
    this.setState({subCatSlug:slug})
    this.setState({myData:response.data.data})
}
}).catch(error=>{
    toast.error("Request failed ! try again");
})


<Toaster position="top-right" reverseOrder={false}/>


    constructor({match}) {
        super();
        this.state={
            Category:match.params.Category,
            ProductData:[],
            isLoading:"",
            MainDiv:"d-none"
        }

    }
    onChange={(event)=>{ setCategoryId(event.target.value)}}

    check Login

    setTimeout( () =>  document.getElementById("selectMenu").click(), 2000 );
    setTimeout(() => {
    this.setState({ position: 1 });
}, 3000);

<Toaster position="top-right" reverseOrder={false}/>
    props.match.params.subCat
{ headers: { 'Authorization': `Bearer  ${token}` } }
    if (error.response.status === 401) {
    LocalStorageHelper.setItem('access_token', '')
    LocalStorageHelper.setItem('user_info', [])
    toast.error("Please login first !");
}


    let userId = ApiUrl.isLogin();

    localStorage.removeItem("access_token");
    localStorage.removeItem("user_info");*/

// const mapStateToProps = state => ({
//     estimateInfo: state.builder.estimateInfo
// });
// export default connect(
//     mapStateToProps,
//     eshop.builder.actions
// )(CartInfo);


    // this.props.setEstimateInfo({
    //                                ...this.props.estimateInfo,
    //                                shippingCost: response.data.data[0].shippingCost
    //                            });


    // this.props.setEstimateInfo({...estimateValue});

    //
    // for (let success of response.data.message) {
    // toast.success(success);
    // setWishlistsAdd(true)
// }

}


const mapStateToProps = state => ({
    setUserInfo: state.builder.setUserInfo
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ApiUrl);