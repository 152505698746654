import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Redirect} from "react-router-dom";
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import toast, {Toaster} from "react-hot-toast";
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import Validation from "../../../validation/Validation";

class OTPVerificationSingup extends Component {
    constructor() {
        super();
        this.state = {
            btn: "Continue",
            UserRedirect: "",
            otpn1: "",
            otpn2: "",
            otpn3: "",
            otpn4: "",
            otpn5: "",
            otpn6: "",
            mobile: LocalStorageHelper.getUserMobile(),
        }
        this.inputFocus = this.inputFocus.bind(this)
        this.otpn1 = this.otpn1.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
        this.onUserRedirect = this.onUserRedirect.bind(this)
    }

    inputFocus = (event) => {
        if (event.key === "Delete" || event.key === "Backspace") {
            let next = event.target.tabIndex - 2;
            if (next > -1) {
                event.target.form.elements[next].focus()
            }
        } else {
            let next = event.target.tabIndex;
            if (next < 6) {
                event.target.form.elements[next].focus()
            }
        }
    }
    validateNumber = (evt) => {
        var e = evt || window.event;
        var key = e.keyCode || e.which;

        if (!e.shiftKey && !e.altKey && !e.ctrlKey &&
            // numbers
            key >= 48 && key <= 57 ||
            // Numeric keypad
            (key >= 96 && key <= 105) ||
            // Backspace and Tab and Enter
            key === 8 || key === 9 || key === 13 ||
            // Home and End
            key === 35 || key === 36 ||
            // left and right arrows
            key === 37 || key === 39 ||
            // Del and Ins
            key === 46 || key === 45) {
            // input is VALID
        } else {
            // input is INVALID
            e.returnValue = false;
            if (e.preventDefault) e.preventDefault();
        }
    }
    otpn1 = (event) => {
        let checkEmpty = event.target.value
        if (checkEmpty) {
            this.setState({otpn1: checkEmpty})
        } else {
            this.setState({otpn1: ""})
        }
    }
    otpn2 = (event) => {
        let checkEmpty = event.target.value
        if (checkEmpty) {
            this.setState({otpn2: checkEmpty})
        } else {
            this.setState({otpn2: ""})
        }
    }
    otpn3 = (event) => {
        let checkEmpty = event.target.value
        if (checkEmpty) {
            this.setState({otpn3: checkEmpty})
        } else {
            this.setState({otpn3: ""})
        }
    }
    otpn4 = (event) => {
        let checkEmpty = event.target.value
        if (checkEmpty) {
            this.setState({otpn4: checkEmpty})
        } else {
            this.setState({otpn4: ""})
        }
    }
    otpn5 = (event) => {
        let checkEmpty = event.target.value
        if (checkEmpty) {
            this.setState({otpn5: checkEmpty})
        } else {
            this.setState({otpn5: ""})
        }
    }
    otpn6 = (event) => {
        let checkEmpty = event.target.value
        if (checkEmpty) {
            this.setState({otpn6: checkEmpty})
        } else {
            this.setState({otpn6: ""})
        }
    }

    onUserRedirect() {
        if (this.state.UserRedirect === true) {
            return <Redirect to={"/signup"}/>
        }
    }

    onNextClick = () => {
        let mobile = this.state.mobile
        let otpCode = this.state.otpn1 + this.state.otpn2 + this.state.otpn3 + this.state.otpn4 + this.state.otpn5 + this.state.otpn6
        if (otpCode.length === 0) {
            toast.error("Otp code required")
        } else if (otpCode.length != 6) {
            toast.error("Invalid otp code")
        } else {
            this.setState({btn: LocalStorageHelper.loadingBtn()})
            LocalStorageHelper.setOptCode(otpCode)
            let myFormData = new FormData();
            myFormData.append("phone", mobile);
            myFormData.append("otp", otpCode);
            myFormData.append("type", 1);
            myFormData.append("ip", "554544");
            Axios.post(ApiUrl.OtpVarify, myFormData).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    toast.success("Verification Completed")
                    this.setState({UserRedirect: true});
                } else if (response.status === 200 && response.data.status === 0) {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                    this.setState({btn: "Continue"})
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
                this.setState({btn: "Continue"})
            })
        }
    }

    reSendCode = () => {
        let mobile = this.state.mobile
        if (mobile.length === 0) {
            toast.error("Mobile Number Required")
        } else if (!(Validation.MobileRegx).test(mobile)) {
            toast.error("Invalid Mobile Number")
        } else {
            this.setState({btn: LocalStorageHelper.loadingBtn()})
            LocalStorageHelper.setUserMobile(this.state.mobile)
            let myFormData = new FormData();
            myFormData.append("phone", mobile);
            myFormData.append("type", 1);
            myFormData.append("ip", "554544");
            Axios.post(ApiUrl.OtpCreate, myFormData).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    toast.success("Verification Completed");
                    document.getElementById('otpn1').value=""
                    document.getElementById('otpn2').value=""
                    document.getElementById('otpn3').value=""
                    document.getElementById('otpn4').value=""
                    document.getElementById('otpn5').value=""
                    document.getElementById('otpn6').value=""
                } else if (response.status === 200 && response.data.status === 2) {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                    this.setState({btn: "Create my account"})
                } else {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                    this.setState({btn: "Create my account"})
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        }
    }

    render() {
        return (
            <div>
                <Header/>
                <MobileHeader/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto">
                            <div className="d-block d-sm-none py-5 my-4 text-center" style={{lineHeight: '18px'}}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>e</strong><span
                                        style={{fontSize: '20px', fontWeight: 300}}>Shoppingmall</span></p>
                                    <span>.XYZ</span>
                                </div>
                            </div>
                            <div className="login-body pb-5 pt-sm-5 my-sm-5 signup-body-mobile-view">
                                <h1 className="title-h3 text-center text-uppercase">OTP VERIFICATION</h1>
                                <p className="text-center">We sent an OTP to your Mobile</p>
                                <form className="form">
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group mb-3">
                                                <input onKeyUp={this.inputFocus} onKeyDown={e => this.validateNumber(e)}
                                                       onChange={this.otpn1} type="number" tabIndex="1" maxLength="1"
                                                       autoComplete="off" className="text-center form-control"
                                                       id="otpn1"/>
                                            </div>
                                        </div>

                                        <div className="col-2">
                                            <div className="form-group mb-3">
                                                <input onKeyUp={this.inputFocus} onKeyDown={e => this.validateNumber(e)}
                                                       onChange={this.otpn2} type="number" tabIndex="2" maxLength="1"
                                                       autoComplete="off" className="text-center form-control"
                                                       id="otpn2"/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group mb-3">
                                                <input onKeyUp={this.inputFocus} onKeyDown={e => this.validateNumber(e)}
                                                       onChange={this.otpn3} type="number" tabIndex="3" maxLength="1"
                                                       autoComplete="off" className="text-center form-control"
                                                       id="otpn3"/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group mb-3">
                                                <input onKeyUp={this.inputFocus} onKeyDown={e => this.validateNumber(e)}
                                                       onChange={this.otpn4} type="number" tabIndex="4" maxLength="1"
                                                       autoComplete="off" className="text-center form-control"
                                                       id="otpn4"/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group mb-3">
                                                <input onKeyUp={this.inputFocus} onKeyDown={e => this.validateNumber(e)}
                                                       onChange={this.otpn5} type="number" tabIndex="5" maxLength="1"
                                                       autoComplete="off" className="text-center form-control"
                                                       id="otpn5"/>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="form-group mb-3">
                                                <input onKeyUp={this.inputFocus} onKeyDown={e => this.validateNumber(e)}
                                                       onChange={this.otpn6} type="number" tabIndex="6" maxLength="1"
                                                       autoComplete="off" className="text-center form-control"
                                                       id="otpn6"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <span onClick={this.onNextClick}
                                              className="btn base-button text-light d-block text-uppercase">{this.state.btn}</span>
                                    </div>
                                    <div className="form-group text-center">
                                                <Link to="#" className="" onClick={this.reSendCode}> Resend Code</Link>
                                            </div> 
                                    <div className="form-group text-center mt-3">
                                         <span> Already have an account?</span>  
                                        <Link to="/login" className="create-link-txt"> Back to login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <Toaster position="top-right" reverseOrder={false}/>
                {this.onUserRedirect()}
            </div>
        );
    }
}

export default OTPVerificationSingup;