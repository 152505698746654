import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import Header from "../Header/Header";
import MobileHeader from "../Header/MobileHeader";
import BannerSlider from "./components/BannerSlider";
import Footer from "../Footer/Footer";
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import { Link, Redirect } from 'react-router-dom';
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../../Loader/Loader"
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";

class Product extends Component {
    constructor() {
        super();
        this.state = {
            myData: [],
            myCategoris: [],
            catSlug: "",
            subCatSlug: "",
            catId: "",
            UserRedirect: false,
            ready: 0,
            empty: 0,
            catReady: 0,
        }
        this.showCategoryProducts = this.showCategoryProducts.bind(this)
        this.showSubCategoryProducts = this.showSubCategoryProducts.bind(this)
        this.onUserRedirect = this.onUserRedirect.bind(this)
    }
    componentDidMount() {
        window.scroll(0, 0)
        Axios.get(ApiUrl.homeProduct).then(response => {
            if (response.status === 200) {
                if (response.data.data.length === 0) {
                    this.setState({ myData: [], ready: 1, empty: 1 })
                    this.props.setCatSlug("")
                } else {
                    this.setState({ myData: response.data.data, catSlug: response.data.data[0].category_id, catId: response.data.data[0].cat_id,ready: 1, empty: 0 })
                    this.props.setCatSlug(response.data.data[0].category_id)
                }
            }
        }).catch(error => {
            this.setState({ ready: 0 })
        })

        Axios.get(ApiUrl.Categories).then(response => {
            if (response.status === 200) {
                this.setState({ myCategoris: response.data.data }
               )
            }
        }).catch(error => {
            this.setState({ catReady: 0 })
        })
        setTimeout(() => {
            this.setState({ catReady: 1 })
        }, 800)
    }
    showCategoryProducts(slug) {
        Axios.get(ApiUrl.CategoryByProduct(slug)).then(response => {
            if (response.status === 200) {
                this.setState({ catSlug: slug })
                if (response.data.data.length === 0) {
                    this.setState({ myData: response.data.data, ready: 1, empty: 1 })
                } else {
                    this.setState({ myData: response.data.data, ready: 1, empty: 0 })
                }
            }
        }).catch(error => {
            this.setState({ ready: 0 })
        })
    }
    showSubCategoryProducts(slug) {
        Axios.get(ApiUrl.SubCategoryByProduct(slug)).then(response => {
            if (response.status === 200) {
                this.setState({ subCatSlug: slug })
                if (response.data.data.length === 0) {
                    this.setState({ myData: response.data.data, ready: 1, empty: 1 })
                } else {
                    this.setState({ myData: response.data.data, ready: 1, empty: 0 })
                }
            }
        }).catch(error => {
            this.setState({ ready: 0 })
        })
    }
    onUserRedirect(slug) {
        if (this.state.UserRedirect === true) {
            this.props.setCatId(this.state.myData[0].cat_id)
            this.props.setSubCatId(this.state.myData[0].sub_cat_id)
            return <Redirect to={{ pathname: `category/${this.state.catSlug}/${this.state.subCatSlug}`, state: { filterData: this.state.myData, catId: this.state.catId } }} />
        }
    }
    render() {
        const myList = this.state.myData
        const myCatList = this.state.myCategoris
        return (
            <Fragment>
                <Header />
                <MobileHeader />
                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="cat-sidebar">
                                <div className="accordion accordion-flush d-block d-md-none" id="side-categories-list">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed mobile-view-cat-main" type="button" data-bs-toggle="collapse" data-bs-target="#cat-menu-1">
                                                <h1 className="text-uppercase mobile-view-cat-title" style={{ paddingLeft: "10px", fontSize: "20px" }}>All Categories</h1>
                                            </button>
                                        </h2>
                                        <div id="cat-menu-1" className="accordion-collapse collapse" data-bs-parent="#cat-menu-1">
                                            <div className="accordion-body">
                                                { this.state.catReady === 0 ? Loader.homeCategory() : (
                                                    <Accordion defaultActiveKey={this.props.catSlug}>
                                                        {
                                                            myCatList && myCatList.map((cat, i) => {
                                                                let downArrow = "";
                                                                if (cat.sub_categories.length > 0) { downArrow = "" } else { downArrow = "down__arrow" }
                                                                return (
                                                                    <Accordion.Item key={i} eventKey={cat.slug}>
                                                                        <Accordion.Header className={downArrow} onClick={() => { this.showCategoryProducts(cat.slug) }}>{cat.name}</Accordion.Header>
                                                                        {
                                                                            cat.sub_categories.map((subCat, i) => {
                                                                                let activeClass
                                                                                if (this.state.subCatSlug == subCat.slug) { activeClass = "active" }
                                                                                return (
                                                                                    <Accordion.Body key={i} className="sub-menu-name">
                                                                                        <p className={activeClass} onClick={() => { this.showSubCategoryProducts(subCat.slug) }}>{subCat.name}</p>
                                                                                    </Accordion.Body>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Accordion.Item>
                                                                )
                                                            })
                                                        }
                                                    </Accordion>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*destop menu*/}
                                <div className="d-none d-md-block">
                                    <h1 className="text-uppercase all-cat-title" style={{ paddingLeft: "10px" }}>All Categories </h1>
                                    <div className="accordion accordion-flush" id="side-categories-list">
                                        { this.state.catReady === 0 ? Loader.homeCategory() : (
                                            <Accordion defaultActiveKey={this.props.catSlug}>
                                                {
                                                    myCatList && myCatList.map((cat, i) => {
                                                        let downArrow = "";
                                                        if (cat.sub_categories.length > 0) { downArrow = "" } else { downArrow = "down__arrow" }
                                                        return (
                                                            <Accordion.Item key={i} eventKey={cat.slug}>
                                                                <Accordion.Header className={downArrow} onClick={() => { this.showCategoryProducts(cat.slug) }}>{cat.name}</Accordion.Header>
                                                                {
                                                                    cat.sub_categories.map((subCat, i) => {
                                                                        let activeClass
                                                                        if (this.state.subCatSlug == subCat.slug) { activeClass = "active" }
                                                                        return (
                                                                            <Accordion.Body key={i} className="sub-menu-name">
                                                                                <p className={activeClass} onClick={() => { this.showSubCategoryProducts(subCat.slug) }}>{subCat.name}</p>
                                                                            </Accordion.Body>
                                                                        )
                                                                    })
                                                                }
                                                            </Accordion.Item>
                                                        )
                                                    })
                                                }
                                            </Accordion>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="slider-img">
                                        <BannerSlider />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                { this.state.ready === 0 ? Loader.homeLoader() : (
                                    <Fragment>

                                        { myList.map((product, i) => {
                                        return (
                                            <div key={i} className="col-lg-4 col-md-6 col-sm-6 col-6">
                                                {
                                                    <Link to={`/product-details/${product.slug}`}>
                                                        <div className="product-box text-center">
                                                            <div className="prod-box-img">
                                                                {product.current_stock === 0 ? (<span className="home-product-stock">Stock Out</span>) : ""}
                                                                <img src={ApiUrl.showImage(product.thumbnail_image)} alt={product.title} className="img-fluid" title={product.title}/>
                                                            </div>
                                                            <div className="prod-box-content py-3 mb-3">
                                                                <h5 className="product-title text-uppercase">{product.name}</h5>
                                                                <h5 className="product-price mb-0">{product.base_discounted_price !== product.base_price ? (<div><del style={{marginRight: "5px", color: "#f00"}}>৳ {product.base_price}</del> <span style={{fontWeight: "600"}}>৳ {product.base_discounted_price}</span></div>) : (<div>৳ {product.base_price}</div>)}</h5>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                }
                                            </div>
                                        )
                                    })}
                                    </Fragment>
                                )}

                                {this.state.empty === 1 && (
                                    <Alert id="dangerMst" variant="danger" className="alrt-msg-show">
                                        <Alert.Heading className="alrt-name">Your Search Criteria Not Match.</Alert.Heading>
                                    </Alert>
                                )}
                                <div className="col-lg-12 text-center mt-2">
                                    {this.state.empty === 0 && (
                                        <button onClick={() => { this.setState({ UserRedirect: true }) }} className="text-uppercase show-all-link">Show all</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Traveler Shop</title>
                        <meta name ="title" content="bangladesh best ecommerce site" />
                        <meta name = "keyword" content="eshop, ecommerce, online shop" />
                        <meta name="description" content= "this e best eshopping mall"/>
                    </Helmet>
                </div>
                <Footer />
                {this.onUserRedirect()}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    setCatSlug: state.builder.setCatSlug,
    catSlug: state.builder.catSlug,
    setCatId: state.builder.setCatId,
    setSubCatId: state.builder.setSubCatId,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(Product);